/**
 * @returns boolean
 */
export function hasAuthToken() {
    const authToken = document.cookie.match('(^|;)\\s*pelcro.user.auth.token\\s*=\\s*([^;]+)')?.pop() || '';
    return authToken !== '';
}

/**
 * @returns boolean
 */
export function isUserLoggedIn() {
    let loggedIn = false;
    if (window.Pelcro && 'user' in window.Pelcro) {
        loggedIn = window.Pelcro.user.isAuthenticated();
    }

    return loggedIn;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns boolean
 */
export function isUserEntitledTo(entitlement) {
    let entitledTo = false;
    if (window.Pelcro && 'user' in window.Pelcro) {
        entitledTo = window.Pelcro.user.isEntitledTo(entitlement);
    }

    return entitledTo;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns boolean
 */
export function isUserSubscribed() {
    let subscribed = false;
    if (window.Pelcro && 'subscription' in window.Pelcro) {
        subscribed = window.Pelcro.subscription.isSubscribedToSite();
    }

    return subscribed;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns boolean
 */
export function isUserNewsletterSubscribed() {
    let newsletterSubscribed = false;
    if (window.Pelcro && 'newsletter' in window.Pelcro) {
        newsletterSubscribed = window.Pelcro.newsletter.isSubscribedToNewsletter();
    }

    return newsletterSubscribed;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns boolean
 */
export function hasUserPurchased() {
    let purchased = false;
    if (window.Pelcro && 'user' in window.Pelcro) {
        const user = window.Pelcro.user.read();
        purchased = 'orders' in user && user.orders.length > 0;
    }

    return purchased;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns string
 */
export function getUserSubscriptionType() {
    let subscriptionType = 'NoSub';
    if (window.Pelcro && 'user' in window.Pelcro) {
        const user = window.Pelcro.user.read();

        if (
            'subscriptions' in user &&
            Array.isArray(user.subscriptions) &&
            user.subscriptions.length > 0
        ) {
            const subscriptionTypes = user.subscriptions.map(
                userSubscription => {
                    if (
                        userSubscription.status == 'active' &&
                        userSubscription.plan.product.name.toLowerCase().includes('bundle')
                    ) {
                        return 'Bundle';
                    } else if (
                        userSubscription.status == 'active' &&
                        userSubscription.plan.product.name.toLowerCase().includes('digital')
                    ) {
                        return 'Digital';
                    } 
                }
            );
            subscriptionType = [...new Set(subscriptionTypes)].join(',');
        }
    }

    return subscriptionType;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns string
 */
export function getUserCurrency() {
    let currency = '';
    if (window.Pelcro && 'user' in window.Pelcro) {
        const user = window.Pelcro.user.read();
        if ('currency' in user && user.currency !== null) {
            currency = user.currency.toUpperCase();
            if (currency !== '') {
                document.cookie = `pelcro-currency=${currency}; path=/; Secure`;
            }
        }
    }

    return currency;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns array
 */
export function getUserSubscriptionCodes() {
    let subscriptionCodes = [];
    if (window.Pelcro && 'user' in window.Pelcro) {
        const user = window.Pelcro.user.read();
        if (
            'subscriptions' in user &&
            Array.isArray(user.subscriptions) &&
            user.subscriptions.length > 0
        ) {
            // We need to fetch the entitlements of each sub as that contains the top level product code e.g. PYWL23
            subscriptionCodes = user.subscriptions.map(
                userSubscription => {
                    if (
                        'entitlements' in userSubscription.plan.product &&
                        Array.isArray(userSubscription.plan.product.entitlements) &&
                        userSubscription.plan.product.entitlements.length > 0 &&
                        userSubscription.status == 'active'
                    ) {
                        return `${userSubscription.plan.product.entitlements}`;
                    }
                }
            );
        }
    }

    return subscriptionCodes;
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns void
 */
export function updateAdTargets() {
    const adTargets = document.querySelectorAll('[data-behaviour="ad-target"]');
    if (adTargets.length > 0) {
        if (isUserSubscribed()) {
            document.body.classList.add('subscriber');
        } else {
            document.body.classList.remove('subscriber');
        }

        if (isUserEntitledTo('noads')) {
            adTargets.forEach(adTarget => {
                const adContainer = adTarget.closest('[data-target="ad-container"]');
                if (adContainer !== null) {
                    adContainer.classList.add('u-hidden');
                    console.log('Ad Hide: ' + adTarget.getAttribute('id'));
                }
            });

            window.__ciads = {};
            document.body.classList.add('hide-ads');
            document.body.classList.remove('show-ads');
            document.body.classList.remove('limited-ads');
        } else if (isUserEntitledTo('limitedads')) {
            const billboardAd = document.querySelector('#div-gpt-ad-1563288058060-0');
            if (billboardAd !== null) {
                billboardAd.classList.add('u-hidden');
                console.log('Ad Hide: ' + billboardAd.getAttribute('id'));
            }

            document.body.classList.add('limited-ads');
            document.body.classList.remove('show-ads');
            document.body.classList.remove('hide-ads');
        } else {
            document.body.classList.add('show-ads');
            document.body.classList.remove('limited-ads');
            document.body.classList.remove('hide-ads');
        }
    }
}

/**
 * Only available one the PelcroUserLoaded event has triggered
 * 
 * @returns void
 */
export function renderAds() {
    // Will need to wait till the user object has loaded before able to check if should display paywall tags
    if (hasAuthToken()) {
        document.addEventListener('PelcroUserLoaded', () => {
            updateAdTargets();
        });
    } else {
        document.addEventListener('PelcroSiteLoaded', () => {
            updateAdTargets();
        });
    }
}

/**
 * Get the number free page views left in paywall
 */
export function getPaywallPageViewsLeft() {
    return document.cookie.match('(^|;)\\s*pelcro_count_of_articles_left\\s*=\\s*([^;]+)')?.pop() || 1;
}

/**
 * This will increment the number free page views left in paywall
 */
export function incrementPaywallPageView() {
    if (window.Pelcro && 'paywall' in window.Pelcro) {
        window.Pelcro.paywall.incrementPageViewFrequency(true);
    }
}

/**
 * @returns boolean
 */
export function canUserDisablePaywall(checkViews = true) {
    let hasEntitlements = false;
    let hasSubscription = isUserSubscribed();
    let hasPageViews = false;

    console.log(`Paywall Render: check subscription ${hasSubscription}`);

    if (
        pelcro_details &&
        'paywall_entitlement' in pelcro_details &&
        pelcro_details.paywall_entitlement !== ''
    ) {
        hasEntitlements = isUserEntitledTo(`${pelcro_details.paywall_entitlement}`);
        console.log(`Paywall Render: check entitlement ${pelcro_details.paywall_entitlement} ${hasEntitlements}`);
    }

    if (
        pelcro_details &&
        'paywall_type' in pelcro_details &&
        pelcro_details.paywall_type === 'soft'
    ) {
        const pageViews = getPaywallPageViewsLeft();
        hasPageViews = getPaywallPageViewsLeft() > 0;
        console.log(`Paywall Render: check page views for type ${pelcro_details.paywall_type} ${hasPageViews} views ${pageViews}`);
    }

    return checkViews
        ? hasSubscription || hasEntitlements || hasPageViews
        : hasSubscription || hasEntitlements
}

/**
 * @returns boolean
 */
export function displayPaywallType(paywallType, paywallEntitlement) {
    let shouldPaywall = false;
    if (paywallType !== '') {
        // Need to ensure we set the meta tag if not added or won't be able to fetch the number of free visists left
        addPaywallTag(`${paywallType}`);

        const isSubscribed = isUserSubscribed();
        const isEntitled = paywallEntitlement !== '' && isUserEntitledTo(`${paywallEntitlement}`);
        const hasPageViews = getPaywallPageViewsLeft() > 0;

        if (paywallType === 'soft') {
            shouldPaywall = !isSubscribed && !isEntitled && !hasPageViews;
        } else if (paywallType === 'hard') {
            shouldPaywall = !isSubscribed && !isEntitled;
        }
    }

    return shouldPaywall;
}

/**
 * You should pass hard, soft or the sku for the offer if there is one configured
 * 
 * @param {string} tag
 * @returns void
 */
export function addPaywallTag(tag) {
    // Need to add the tag first in order to trigger the modal
    if (document.querySelectorAll(`meta[content="${tag}"]`).length <= 0) {
        var meta = document.createElement('meta');
        meta.setAttribute('property', 'article:tag');
        meta.content = tag;
        document.getElementsByTagName('head')[0].appendChild(meta);
        console.log(`Paywall Render: trigger ${tag}`);
    }
}

/**
 * This will add the paywall header tags to the site head
 */
export function addPaywallTags() {
    if (
        pelcro_details && 'paywall_tags' in pelcro_details &&
        Array.isArray(pelcro_details.paywall_tags) &&
        pelcro_details.paywall_tags.length > 0
    ) {
        for (let i = 0; i < pelcro_details.paywall_tags.length; i++) {
            addPaywallTag(`${pelcro_details.paywall_tags[i]}`);
        }
    }
}

/**
 * If logged in need to check once the user data is loaded 
 */
export function renderPaywall() {
    // Will need to wait till the user object has loaded before able to check if should display paywall tags
    if (hasAuthToken()) {
        document.addEventListener('PelcroUserLoaded', () => {
            if (!canUserDisablePaywall(false)) {
                addPaywallTags();
            }
        });
    } else {
        document.addEventListener('PelcroSiteLoaded', () => {
            addPaywallTags();
        });
    }
}

/**
 * This relies on renderPaywall setting the paywall tags first correct tags that 
 * relies on PelcroPaywallDisplayed and PelcroPaywallNotDisplayed events triggeing to set show flag
 */
export function pushSiteWideDataLayer(showPaywall = false) {
    const paywallType = pelcro_details && 'paywall_type' in pelcro_details && pelcro_details.paywall_type != ''
        ? pelcro_details.paywall_type
        : 'none';

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'paywallType': paywallType, // will be 'hard', 'soft' or left blank
        'loggedInStatus': isUserLoggedIn() ? 'true' : 'false',
        'subscribedStatus': isUserSubscribed() ? 'true' : 'false',
        'subscriberType': getUserSubscriptionType(), // will be 'Digital', 'Bundle' or 'NoSub'
        'shouldPaywall': paywallType == 'hard' ? 'true' : 'false',
        'showPaywall': showPaywall ? 'true' : 'false',
        'articlePublishedDate': pelcro_details && 'article_published_date' in pelcro_details
            ? pelcro_details.article_published_date
            : '',
        'articleEditDate': pelcro_details && 'article_edit_date' in pelcro_details
            ? pelcro_details.article_edit_date
            : '',
        'articleID': pelcro_details && 'article_id' in pelcro_details
            ? pelcro_details.article_id
            : '',
        'pageType': pelcro_details && 'page_type' in pelcro_details
            ? pelcro_details.page_type
            : '',
        'event': 'sitewideDatalayer',
    });
}

/**
 * Fetch the user country and currency based on specifc country
 * 
 * @returns {object}
 */
export async function getUserGeoData() {
    let country = document.cookie.match('(^|;)\\s*geo-country\\s*=\\s*([^;]+)')?.pop() || '';
    let currency = document.cookie.match('(^|;)\\s*geo-currency\\s*=\\s*([^;]+)')?.pop() || '';
    let locale = document.cookie.match('(^|;)\\s*geo-locale\\s*=\\s*([^;]+)')?.pop() || '';

    if (country === '' || currency === '' || locale === '') {
        await fetch(`${wp_ajax.ajax_url}?action=getUserGeoData`)
            .then(response => response.json())
            .then(data => {
                if ('country' in data) {
                    country = data.country;
                }

                if ('currency' in data) {
                    currency = data.currency;
                }

                if ('locale' in data) {
                    locale = data.locale;
                }
            })
            .catch(error => {
                console.error('Get User GEO Data: ', error);
            });

        if (country !== '') {
            document.cookie = `geo-country=${country}; path=/; Secure`;
        }

        if (currency !== '') {
            document.cookie = `geo-currency=${currency}; path=/; Secure`;
        }

        if (locale !== '') {
            document.cookie = `geo-locale=${locale}; path=/; Secure`;
        }
    }

    return {
        country: country,
        currency: currency,
        locale: locale,
    };
}

/**
 * Formats the price based on specifc currency and locale
 * 
 * @param {string} currency
 * @param {string} country
 * @returns {string}
 */
export function getGeoPrice(prices = [], currency = 'GBP', country = 'GB') {
    let price = null;
    if (isUserLoggedIn()) {
        // GBP is both UK and ROW prices we need to check the user country
        if (currency === 'GBP') {
            if (country === 'GB') {
                price = prices.find((geoPrice) => geoPrice.currency.toUpperCase() === 'GBP' && geoPrice.country.toUpperCase() === 'GB');
            } else {
                price = prices.find((geoPrice) => geoPrice.currency.toUpperCase() === 'GBP' && geoPrice.country === '');
            }
        } else {
            price = prices.find((geoPrice) => geoPrice.currency.toUpperCase() === currency);
        }
    } else {
        price = prices.find((geoPrice) => geoPrice.country.toUpperCase() === country);

        // If there are no SKUs in the users country then will need to display SKUs with no country
        if (price === null) {
            price = prices.find((geoPrice) => geoPrice.country === '');
        }
    }

    const locale = 'locale' in Intl.NumberFormat().resolvedOptions()
        ? Intl.NumberFormat().resolvedOptions().locale
        : 'en-GB'

    return typeof price === 'object'
        ? formatPrice(price?.price, price?.currency, locale)
        : '';
}

/**
 * Formats the price based on specifc currency and locale
 * 
 * @param {string} price
 * @param {string} currency
 * @param {string} locale
 * @returns {string}
 */
export function formatPrice(price, currency = 'GBP', locale = 'en-GB') {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    });

    return `${formatter.format(price)}`;
}
