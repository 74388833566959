import {
    pushSiteWideDataLayer
} from '../tools/utilities';

document.addEventListener('PelcroSubscriptionCreate', (response, e) => {
    if (typeof fbq == 'function' && response?.detail?.data?.subscriptions.length > 0) {
        fbq('track', 'Subscribe', {
            currency: response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.currency.toUpperCase(),
            value: value,
            predicted_ltv: value * response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.interval_count,
            contents: [
                {
                    id: response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.id,
                    quantity: 1
                }
            ],
            content_ids: [
                response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.id
            ],
            content_type: 'product'
        });
    }
});

document.addEventListener('PelcroOrderCreated', (response, e) => {
    if (typeof fbq == 'function' && response?.detail) {
        fbq('track', 'Purchase', {
            currency: response.detail.currency.toUpperCase(),
            value: response.detail.amount > 0
                ? response.detail.amount / 100
                : 0, // Stripe doesn't store prices as decimals so need to divide by 100 e.g. 19.99 is stored as 1999
            contents: response.detail.items.map(
                item => {
                    return {
                        id: item.product_sku_id,
                        quantity: item.quantity
                    };
                }
            ),
            content_ids: response.detail.items.map(
                item => item.product_sku_id
            ),
            content_type: 'product'
        });
    }
});

document.addEventListener('PelcroElementsCartItemAdded', (response, e) => {
    if (typeof fbq == 'function' && response?.detail) {
        fbq('track', 'AddToCart', {
            currency: response.detail.currency.toUpperCase(),
            value: response.detail.price > 0
                ? response.detail.price / 100
                : 0, // Stripe doesn't store prices as decimals so need to divide by 100 e.g. 19.99 is stored as 1999
            contents: [
                {
                    id: response.detail.product_id,
                    quantity: 1
                }
            ],
            content_ids: [
                response.detail.product_id
            ],
            content_name: response.detail.name,
            content_type: 'product'
        });
    }
});

document.addEventListener('PelcroPaywallDisplayed', () => {
    pushSiteWideDataLayer(true);
});

document.addEventListener('PelcroPaywallNotDisplayed', () => {
    pushSiteWideDataLayer(false);
});

document.addEventListener('PelcroUserRegister', (response, e) => {
    if (typeof fbq == 'function' && response?.detail?.data) {
        fbq('track', 'CompleteRegistration', {
            currency: response.detail.data.currency.toUpperCase(),
            value: 0,
            status: true
        });
    }
});

document.addEventListener('PelcroUserLoggedIn', () => {
    window.location.reload();
});

document.addEventListener('PelcroUserLogout', () => {
    window.location.reload();
});
